import axios from '@/common/axios'
import qs from 'qs'

export function addInitialBacteriaReport (data) {
  return axios({
    method: 'post',
    url: '/quality/initialBacteriaReport/add',
    data: qs.stringify(data)
  })
}

export function deleteInitialBacteriaReport (id) {
  return axios({
    method: 'delete',
    url: '/quality/initialBacteriaReport/delete/' + id
  })
}

export function updateInitialBacteriaReport (data) {
  return axios({
    method: 'put',
    url: '/quality/initialBacteriaReport/update',
    data: qs.stringify(data)
  })
}

export function selectInitialBacteriaReportInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/initialBacteriaReport/info/' + id
  })
}

export function selectInitialBacteriaReportList (query) {
  return axios({
    method: 'get',
    url: '/quality/initialBacteriaReport/list',
    params: query
  })
}

export function reviewInitialBacteriaReport (data) {
  return axios({
    method: 'put',
    url: '/quality/initialBacteriaReport/updateReview',
    data: qs.stringify(data)
  })
}

export function approvalInitialBacteriaReport (data) {
  return axios({
    method: 'put',
    url: '/quality/initialBacteriaReport/updateApprove',
    data: qs.stringify(data)
  })
}
