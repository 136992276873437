<template>
  <div id="initialBacteriaReport">
    <el-dialog
      :title="initialBacteriaReportFormTitle"
      width="1200px"
      :visible.sync="initialBacteriaReportDialogVisible"
      :close-on-click-modal="false"
      @close="initialBacteriaReportDialogClose"
    >
      <el-form
        ref="initialBacteriaReportFormRef"
        :model="initialBacteriaReportForm"
        :rules="initialBacteriaReportFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="编号" prop="formNo">
              <el-input
                v-model="initialBacteriaReportForm.formNo"
                placeholder="请输入编号"
                clearable
                :disabled="disabled"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="名称" prop="name">
              <el-input
                v-model="initialBacteriaReportForm.name"
                placeholder="请输入名称"
                clearable
                :disabled="disabled"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="规格型号" prop="spec">
              <el-input
                v-model="initialBacteriaReportForm.spec"
                placeholder="请输入规格型号"
                clearable
                :disabled="disabled"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="批号" prop="batchNo">
              <el-input
                v-model="initialBacteriaReportForm.batchNo"
                placeholder="请输入批号"
                clearable
                :disabled="disabled"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="抽检数量" prop="inspectionQuantity">
              <el-input
                v-model="initialBacteriaReportForm.inspectionQuantity"
                placeholder="请输入抽检数量"
                clearable
                :disabled="disabled"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检测标准">
              <el-input
                value="≤100cfu/件"
                readonly
                :disabled="disabled"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="报告日期" prop="reportDate">
              <el-date-picker
                v-model="initialBacteriaReportForm.reportDate"
                placeholder="请选择报告日期"
                value-format="yyyy-MM-dd"
                :disabled="disabled"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="操作步骤">
          1、随机抽取
          <el-input
            v-model="initialBacteriaReportForm.word1"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          个产品，
          <el-input
            v-model="initialBacteriaReportForm.word2"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          个PE袋，
          <el-input
            v-model="initialBacteriaReportForm.word3"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          个纸塑袋，进行检验。<br>
          2、供试液制备<br>
          产品：在超净工作台上，用将产品一起放到无菌烧杯中，用
          <el-input
            v-model="initialBacteriaReportForm.word4"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ml0.9％无菌氯化钠溶液（批号：
          <el-input
            v-model="initialBacteriaReportForm.word5"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ）进行反复冲洗，使其与洗脱液充分接触，此时的稀释度为
          <el-input
            v-model="initialBacteriaReportForm.word6"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          。<br>
          PE袋：在超净工作台上，用无菌剪刀剪碎放到无菌烧杯中用，
          <el-input
            v-model="initialBacteriaReportForm.word7"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ml0.9％无菌氯化钠溶液进行浸泡，无菌状态下浸泡
          <el-input
            v-model="initialBacteriaReportForm.word8"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          min，此时的稀释度为
          <el-input
            v-model="initialBacteriaReportForm.word9"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          。<br>
          纸塑袋：在超净工作台上，用无菌剪刀剪碎放到无菌烧杯中用，
          <el-input
            v-model="initialBacteriaReportForm.word10"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ml0.9％无菌氯化钠溶液进行浸泡，无菌状态下浸泡
          <el-input
            v-model="initialBacteriaReportForm.word11"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          min，此时的稀释度为
          <el-input
            v-model="initialBacteriaReportForm.word12"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          。<br>
          3、接种（倾注法）：取
          <el-input
            v-model="initialBacteriaReportForm.word13"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          个无菌培养皿，其中
          <el-input
            v-model="initialBacteriaReportForm.word14"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          个培养皿分别注入产品供试液
          <el-input
            v-model="initialBacteriaReportForm.word15"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ml，
          <el-input
            v-model="initialBacteriaReportForm.word16"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          个培养皿分别注入装PE袋供试液
          <el-input
            v-model="initialBacteriaReportForm.word17"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ml，
          <el-input
            v-model="initialBacteriaReportForm.word18"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          个培养皿分别注入装纸塑袋供试液
          <el-input
            v-model="initialBacteriaReportForm.word19"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ml；另外
          <el-input
            v-model="initialBacteriaReportForm.word20"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          个注入0.9％无菌氯化钠溶液
          <el-input
            v-model="initialBacteriaReportForm.word21"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ml，作为空白对照。将以上灭菌后的培养基冷却至45℃，无菌操作条件下将培养基注入培养皿，每皿约20ml。<br>
          4、培养将胰酪大豆胨琼脂培养基（批号：
          <el-input
            v-model="initialBacteriaReportForm.word22"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ）置于
          <el-input
            v-model="initialBacteriaReportForm.word23"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ℃（设备编号及名称：
          <el-input
            v-model="initialBacteriaReportForm.word24"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ）中培养3天，将沙氏葡萄糖琼脂培养基（批号：
          <el-input
            v-model="initialBacteriaReportForm.word25"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ）置于
          <el-input
            v-model="initialBacteriaReportForm.word26"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ℃的（设备编号及名称：
          <el-input
            v-model="initialBacteriaReportForm.word27"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ）中培养5天。
        </el-form-item>
        <el-form-item label="微生物检测">
          <vxe-toolbar
            v-if="initialBacteriaReportFormTitle === '新增初始污染菌检验报告'
              || initialBacteriaReportFormTitle === '修改初始污染菌检验报告'"
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="reportDetailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="temperature"
              title="样品"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="trainingDate"
              title="时间"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="dish1"
              title="培养皿1（cfu/ml）"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="dish2"
              title="培养皿2（cfu/ml）"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="dish3"
              title="培养皿3（cfu/ml）"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="averageColony"
              title="平均菌落数（cfu/ml）"
              width="120"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="dilutionRatio"
              title="稀释倍数"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="colony"
              title="菌落（cfu/件）"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="blank"
              title="空白对照（cfu）"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="result"
              title="结果"
              :edit-render="{
                name: '$select',
                props: {clearable: true},
                options: [
                  { value: 1, label: '合格' },
                  { value: 0, label: '不合格' }
                ]
              }"
            />
            <!-- <vxe-table-column field="result" title="结果" width="200">
              <template v-slot="{row,column}">
                <vxe-radio-group v-model="row[column.property]">
                  <vxe-radio :label="1" content="合格" />
                  <vxe-radio :label="0" content="不合格" />
                </vxe-radio-group>
              </template>
            </vxe-table-column> -->
            <vxe-table-column
              v-if="initialBacteriaReportFormTitle === '新增初始污染菌检验报告'
                || initialBacteriaReportFormTitle === '修改初始污染菌检验报告'"
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="结论" prop="conclusion">
              <el-radio-group
                v-model="initialBacteriaReportForm.conclusion"
                :disabled="disabled"
              >
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="initialBacteriaReportForm.remarks"
                placeholder="请输入备注"
                clearable
                :disabled="disabled"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复核结果" prop="reviewResult">
              <el-radio-group
                v-model="initialBacteriaReportForm.reviewResult"
                :disabled="initialBacteriaReportFormTitle !== '复核初始污染菌检验报告'
                  && initialBacteriaReportFormTitle !== '初始污染菌检验报告详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准结果" prop="approvalResult">
              <el-radio-group
                v-model="initialBacteriaReportForm.approvalResult"
                :disabled="initialBacteriaReportFormTitle !== '批准初始污染菌检验报告'
                  && initialBacteriaReportFormTitle !== '初始污染菌检验报告详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="initialBacteriaReportDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="initialBacteriaReportFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="批号">
        <el-input v-model="searchForm.batchNo" placeholder="请输入批号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['INITIAL_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="initialBacteriaReportPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="formNo" label="编号" />
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="spec" label="规格型号" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="inspectionQuantity" label="抽检数量" />
      <el-table-column label="检测标准">
        <template>≤100cfu/件</template>
      </el-table-column>
      <el-table-column label="报告日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reportDate">{{ scope.row.reportDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="结论">
        <template slot-scope="scope">
          <span v-if="scope.row.conclusion === 0">不合格</span>
          <span v-if="scope.row.conclusion === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="inspector" label="检验人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.inspectionDate">{{ scope.row.inspectionDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate">{{ scope.row.reviewDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">通过</span>
          <span v-if="scope.row.reviewResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalDate">{{ scope.row.approvalDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="批准结果">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalResult === 1">通过</span>
          <span v-if="scope.row.approvalResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['INITIAL_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['INITIAL_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['INITIAL_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            复核
          </el-button>
          <el-button
            v-if="checkPermission(['INITIAL_APPROVAL']) && scope.row.status === 2"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleApproval(scope.$index, scope.row)"
          >
            批准
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="initialBacteriaReportPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addInitialBacteriaReport,
  deleteInitialBacteriaReport,
  updateInitialBacteriaReport,
  selectInitialBacteriaReportInfo,
  selectInitialBacteriaReportList,
  reviewInitialBacteriaReport,
  approvalInitialBacteriaReport
} from '@/api/quality/initialBacteriaReport'
import moment from 'moment'

export default {
  data () {
    return {
      initialBacteriaReportDialogVisible: false,
      initialBacteriaReportFormTitle: '',
      initialBacteriaReportForm: {
        id: '',
        formNo: '',
        name: '',
        spec: '',
        batchNo: '',
        inspectionQuantity: '',
        reportDate: '',
        conclusion: '',
        remarks: '',
        reviewResult: '',
        approvalResult: '',
        status: '',
        word1: '',
        word2: '',
        word3: '',
        word4: '',
        word5: '',
        word6: '',
        word7: '',
        word8: '',
        word9: '',
        word10: '',
        word11: '',
        word12: '',
        word13: '',
        word14: '',
        word15: '',
        word16: '',
        word17: '',
        word18: '',
        word19: '',
        word20: '',
        word21: '',
        word22: '',
        word23: '',
        word24: '',
        word25: '',
        word26: '',
        word27: '',
        reportDetailJson: '',
        taskId: ''
      },
      initialBacteriaReportFormRules: {
        batchNo: [{ required: true, message: '批号不能为空', trigger: ['blur', 'change']}]
      },
      initialBacteriaReportPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        batchNo: ''
      },
      reportDetailList: []
    }
  },
  computed: {
    disabled: function () {
      return this.initialBacteriaReportFormTitle !== '新增初始污染菌检验报告'
      && this.initialBacteriaReportFormTitle !== '修改初始污染菌检验报告'
      && this.initialBacteriaReportFormTitle !== '初始污染菌检验报告详情'
    }
  },
  created () {
    selectInitialBacteriaReportList(this.searchForm).then(res => {
      this.initialBacteriaReportPage = res
    })
  },
  methods: {
    initialBacteriaReportDialogClose () {
      this.$refs.initialBacteriaReportFormRef.resetFields()
      this.initialBacteriaReportForm.word1 = ''
      this.initialBacteriaReportForm.word2 = ''
      this.initialBacteriaReportForm.word3 = ''
      this.initialBacteriaReportForm.word4 = ''
      this.initialBacteriaReportForm.word5 = ''
      this.initialBacteriaReportForm.word6 = ''
      this.initialBacteriaReportForm.word7 = ''
      this.initialBacteriaReportForm.word8 = ''
      this.initialBacteriaReportForm.word9 = ''
      this.initialBacteriaReportForm.word10 = ''
      this.initialBacteriaReportForm.word11 = ''
      this.initialBacteriaReportForm.word12 = ''
      this.initialBacteriaReportForm.word13 = ''
      this.initialBacteriaReportForm.word14 = ''
      this.initialBacteriaReportForm.word15 = ''
      this.initialBacteriaReportForm.word16 = ''
      this.initialBacteriaReportForm.word17 = ''
      this.initialBacteriaReportForm.word18 = ''
      this.initialBacteriaReportForm.word19 = ''
      this.initialBacteriaReportForm.word20 = ''
      this.initialBacteriaReportForm.word21 = ''
      this.initialBacteriaReportForm.word22 = ''
      this.initialBacteriaReportForm.word23 = ''
      this.initialBacteriaReportForm.word24 = ''
      this.initialBacteriaReportForm.word25 = ''
      this.initialBacteriaReportForm.word26 = ''
      this.initialBacteriaReportForm.word27 = ''
      this.reportDetailList = []
    },
    initialBacteriaReportFormSubmit () {
      if (this.initialBacteriaReportFormTitle === '初始污染菌检验报告详情') {
        this.initialBacteriaReportDialogVisible = false
        return
      }
      this.$refs.initialBacteriaReportFormRef.validate(async valid => {
        if (valid) {
          if (this.initialBacteriaReportFormTitle === '新增初始污染菌检验报告') {
            this.initialBacteriaReportForm.id = ''
            this.initialBacteriaReportForm.status = 1
            this.initialBacteriaReportForm.reportDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await addInitialBacteriaReport(this.initialBacteriaReportForm)
          } else if (this.initialBacteriaReportFormTitle === '修改初始污染菌检验报告') {
            this.initialBacteriaReportForm.reportDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await updateInitialBacteriaReport(this.initialBacteriaReportForm)
          } else if (this.initialBacteriaReportFormTitle === '复核初始污染菌检验报告') {
            this.initialBacteriaReportForm.status = 2
            await reviewInitialBacteriaReport(this.initialBacteriaReportForm)
          } else if (this.initialBacteriaReportFormTitle === '批准初始污染菌检验报告') {
            this.initialBacteriaReportForm.status = 3
            await approvalInitialBacteriaReport(this.initialBacteriaReportForm)
          }
          this.initialBacteriaReportPage = await selectInitialBacteriaReportList(this.searchForm)
          this.initialBacteriaReportDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.initialBacteriaReportFormTitle = '新增初始污染菌检验报告'
      this.initialBacteriaReportDialogVisible = true
      this.initialBacteriaReportForm.formNo = 'CR' + moment().format('YYYYMMDD') + '-'
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteInitialBacteriaReport(row.id)
        if (this.initialBacteriaReportPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.initialBacteriaReportPage = await selectInitialBacteriaReportList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.initialBacteriaReportFormTitle = '修改初始污染菌检验报告'
      this.initialBacteriaReportDialogVisible = true
      this.selectInitialBacteriaReportInfoById(row.id)
    },
    handleReview (index, row) {
      this.initialBacteriaReportFormTitle = '复核初始污染菌检验报告'
      this.initialBacteriaReportDialogVisible = true
      this.selectInitialBacteriaReportInfoById(row.id)
    },
    handleApproval (index, row) {
      this.initialBacteriaReportFormTitle = '批准初始污染菌检验报告'
      this.initialBacteriaReportDialogVisible = true
      this.selectInitialBacteriaReportInfoById(row.id)
    },
    handleInfo (index, row) {
      this.initialBacteriaReportFormTitle = '初始污染菌检验报告详情'
      this.initialBacteriaReportDialogVisible = true
      this.selectInitialBacteriaReportInfoById(row.id)
    },
    selectInitialBacteriaReportInfoById (id) {
      selectInitialBacteriaReportInfo(id).then(res => {
        this.initialBacteriaReportForm.id = res.id
        this.initialBacteriaReportForm.formNo = res.formNo
        this.initialBacteriaReportForm.name = res.name
        this.initialBacteriaReportForm.spec = res.spec
        this.initialBacteriaReportForm.batchNo = res.batchNo
        this.initialBacteriaReportForm.inspectionQuantity = res.inspectionQuantity
        this.initialBacteriaReportForm.reportDate = res.reportDate
        this.initialBacteriaReportForm.conclusion = res.conclusion
        this.initialBacteriaReportForm.remarks = res.remarks
        this.initialBacteriaReportForm.reviewResult = res.reviewResult
        this.initialBacteriaReportForm.approvalResult = res.approvalResult
        this.initialBacteriaReportForm.status = res.status
        this.initialBacteriaReportForm.word1 = res.word1
        this.initialBacteriaReportForm.word2 = res.word2
        this.initialBacteriaReportForm.word3 = res.word3
        this.initialBacteriaReportForm.word4 = res.word4
        this.initialBacteriaReportForm.word5 = res.word5
        this.initialBacteriaReportForm.word6 = res.word6
        this.initialBacteriaReportForm.word7 = res.word7
        this.initialBacteriaReportForm.word8 = res.word8
        this.initialBacteriaReportForm.word9 = res.word9
        this.initialBacteriaReportForm.word10 = res.word10
        this.initialBacteriaReportForm.word11 = res.word11
        this.initialBacteriaReportForm.word12 = res.word12
        this.initialBacteriaReportForm.word13 = res.word13
        this.initialBacteriaReportForm.word14 = res.word14
        this.initialBacteriaReportForm.word15 = res.word15
        this.initialBacteriaReportForm.word16 = res.word16
        this.initialBacteriaReportForm.word17 = res.word17
        this.initialBacteriaReportForm.word18 = res.word18
        this.initialBacteriaReportForm.word19 = res.word19
        this.initialBacteriaReportForm.word20 = res.word20
        this.initialBacteriaReportForm.word21 = res.word21
        this.initialBacteriaReportForm.word22 = res.word22
        this.initialBacteriaReportForm.word23 = res.word23
        this.initialBacteriaReportForm.word24 = res.word24
        this.initialBacteriaReportForm.word25 = res.word25
        this.initialBacteriaReportForm.word26 = res.word26
        this.initialBacteriaReportForm.word27 = res.word27
        this.initialBacteriaReportForm.taskId = res.taskId
        this.reportDetailList = res.reportDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectInitialBacteriaReportList(this.searchForm).then(res => {
        this.initialBacteriaReportPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectInitialBacteriaReportList(this.searchForm).then(res => {
        this.initialBacteriaReportPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectInitialBacteriaReportList(this.searchForm).then(res => {
        this.initialBacteriaReportPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['INITIAL_REVIEW']) && row.status === 1) {
        return 'review'
      } else if (this.checkPermission(['INITIAL_APPROVAL']) && row.status === 2) {
        return 'review'
      }
      return ''
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    }
  }
}
</script>

<style>
</style>
